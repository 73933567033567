import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@patternfly/react-core';
import { storesContext } from '../../../stores';
import CertificateOfAttendanceModal from '../Modal';
import Store from './store';
import { ExternalLinkAltIcon } from '@patternfly/react-icons';

const CertificateOfAttendanceButton = props => {
  const { courseSlug, timestamp, classId = null, academyId=null, badges=null } = props;

  const { t } = useTranslation();

  const { userStore, 
    progressStore,
    courseStore,
    configStore
  } = useContext(storesContext);

  const store = useLocalStore(() => new Store(
    userStore,
    progressStore,
    courseStore,
    configStore
  ));

  const entityValue = userStore.isStudent ? classId : courseSlug;
  store.setEntityValue(entityValue);
  

  const badge_url = store.getBadgeURL(badges, courseSlug);

  const setCertificateOfAttendanceModalVisibility = visible => {
    store.certificateOfAttendanceModalVisibility = visible;
  };

  return (
    <React.Fragment>
      {store.shouldShowLegacyCertificateOfAttendance === true ? (
          <Button
            variant="secondary"
            onClick={() => setCertificateOfAttendanceModalVisibility(true)}
          >
            {t('Certificate of Attendance')}
          </Button>
        ) : badge_url !== null && (
          <Button
            variant="danger"
            onClick={() => window.open(badge_url, '_blank')}
          >
            <ExternalLinkAltIcon className="external-icon" />
            {t('Download digital badge')}
          </Button>
        )
      }
      
      {store.certificateOfAttendanceModalVisibility && (
        <CertificateOfAttendanceModal
          user={userStore.user}
          courseSlug={courseSlug}
          classId={classId}
          academyId={academyId}
          timestamp={timestamp}
          setVisibility={setCertificateOfAttendanceModalVisibility}
        />
      )}
    </React.Fragment>
  );
};

CertificateOfAttendanceButton.propTypes = {
  courseSlug: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  classId: PropTypes.string,
  academyId:PropTypes.string
};

export default observer(CertificateOfAttendanceButton);