/**
 * @file Store for class/batch creation with course name, date range, emails, and csv
 * @author Attila farkas <afarkas@redhat.com>
 */

import { computed, observable, action, toJS, autorun } from 'mobx';
import { createContext } from 'react';
import {
  getClasses,
  postClasses,
  patchClasses,
  postFile,
} from '../../../services/ClassService';

import { randomCardColor } from '../../../services/UtilityService';
import * as merge from 'deepmerge';
import moment from 'moment';

class CreateClassStore {
  constructor(catalogStore, userStore,academyStore) {
    this.catalogStore = catalogStore;
    this.userStore = userStore;
    this.academyStore = academyStore;
    // Auto-fetch academy
    autorun(() => {
      
      this.fetchAcademy(this.academyStore?.academyLoaded);
    });
  }

  @observable students = [];

  @observable studentsFromCSV = [];

  @observable fileForBYO = {};

  @observable submitSuccess = false;

  @observable submitFailure = false;
  @observable showDeleteStudentModal = false;
  @observable studentToDelete = null;

  @observable dateRange = {
    from: undefined,
    to: undefined,
  };

  @observable isCalendarOpen = false;

  @observable numberOfMonths = 2;

  @observable allClasses = 0;

  @observable classToCreate = {
    students: [],
    course_title: '',
    start_date: '',
    end_date: '',
    class_name: '',
    course_slug: '',
    status: '',
    decor: '',
    label: '',
    coa_percentage: 0,
    custom_class: false,
  };

  @observable academyDetails = {};

  @observable academyInstructors = [];

  @observable parsingEmails = false;

  @observable courseName = '';

  @observable courseCode = '';

  @observable iltConfirmed = false;

  @observable lockConfirmed = false;

  @observable invalidCsvData = false;

  @observable byoConfirmed = false;

  @observable ownConfirmed = false;

  @observable selectedCourse = {
    courseName: '',
    courseCode: '',
    courseSlug: '',
  };

  @observable loading = false;

  @observable submitting = false;

  @observable createdClass;

  @observable classIdOfExistingClass;

  @observable attachedFiles = [];

  @observable _currentPage = 1;

  @observable studentEntriesPerPage = 10;

  @observable classSaveMessage = '';

  @observable enrolledStudents = [];

  @observable isInfoPanelOpen = true;

  @computed get disabled() {
    return this.students?.length === 0;
  }

  @action setDateRange = (range) => {
    this.dateRange = merge(toJS(this.dateRange), range || {});
  };

  @action setEmails = (newEmails) => {
    this.students = newEmails;
  };

  @action resetCalendar = () => {
    this.dateRange.from = undefined;
    this.dateRange.to = undefined;
  };

  @action toggleCalendar = () => {
    this.isCalendarOpen = !this.isCalendarOpen;
  };

  @computed get allEmails() {
    return this.students;
  }

  @action removeStudent = (student) => {
    this.students = this.students.filter(
      (item) => item.username.toLowerCase() !== student.username.toLowerCase(),
    );
  };

  @action fetchAcademy = async (academyLoaded) => {
    let academy = {};
    if(academyLoaded){
      academy = this.academyStore.academyDetails;
    }
    if (academy) {
      
      this.academyDetails.academy_id = academy?.doc_id;
      this.academyDetails.academy_region = academy?.academy_region?.toUpperCase();

      const removeOf = academy?.institution_name?.replace('of', '');
      const initials = removeOf?.match(/\b(\w)/g);
      this.academyDetails.initials = initials?.join('').toUpperCase();
    }

    this.academyInstructors.push(this.userStore?.user?.username);

    return this.academyDetails;
  };

  @computed get academyIdentifier() {
    const plusOne = this.allClasses + 1;
    const sixDigit = '0000' + plusOne;
    const counter = sixDigit.substr(sixDigit.length - 5);
    return (
      (this.byoConfirmed ? 'BYOC' : this.selectedCourse.courseCode) +
      '-' +
      this.academyDetails.academy_region +
      '-' +
      this.academyDetails.initials +
      counter
    );
  }

  @computed get academyIdentifierForExistingClass() {
    const keepIdParts = this.classToCreate.class_name.substr(
      this.classToCreate.class_name.indexOf('-') + 1,
    );

    const changedClass = this.selectedCourse.courseCode + '-' + keepIdParts;
    return changedClass;
  }

  @action getClassId = async () => {
    const classes = await getClasses();
    if (classes) {
      return classes.find(
        (entry) => entry.class_name === this.classToCreate.class_name,
      ).doc_id;
    }
  };

  @action submitClass = async (t) => {
    this.submitting = true;
    this.classToCreate.students = await this.students;
    this.classToCreate.course_title = this.selectedCourse.courseName;
    this.classToCreate.start_date = moment(this.dateRange.from).utc();
    this.classToCreate.end_date = moment(this.dateRange.to).utc();
    this.classToCreate.class_name = this.academyIdentifier;
    this.classToCreate.course_slug = this.selectedCourse.courseSlug;

    this.classToCreate.academy_id = this.academyDetails.academy_id;
    this.classToCreate.academy_region = this.academyDetails.academy_region;
    this.classToCreate.initials = this.academyDetails.initials;
    this.classToCreate.instructors = this.academyInstructors;

    this.classToCreate.status = 'created';

    this.classToCreate.course_lock_solution = this.lockConfirmed;

    const file = this.fileForBYO.file;

    this.classToCreate.decor = randomCardColor();

    const classObject = this.classToCreate;
    
    try {      
      const result = await postClasses(classObject);

      if (result !== `Couldn't create class`) {
        if (this.byoConfirmed) {
          const classId = await this.getClassId();
          const fileUploaded = await postFile(classId, file);

          if (fileUploaded !== `Couldn't upload file`) {
            this.submitSuccess = true;
            this.cancelForm();
          } else {
            this.submitFailure = true;
          }
        } else {
          this.submitSuccess = true;
          this.cancelForm();
        }
      }
    } catch (err) {
      if (
        err?.response?.status === 400 &&
        err.response.data.error.includes('Some students were already enrolled')
      ) {
        this.enrolledStudents = err.response.data.data;
        this.classSaveMessage =
          (this.enrolledStudents?.length) +
          ' ' + 
          (this.enrolledStudents.length === 1 ? t('student from this list is ') : t('students from this list are ')) + 
          t('already enrolled in a previous ') + this.classToCreate.course_slug.toUpperCase() + 
          t(' course. Please click on "Proceed without duplicates" to continue creating a new class.');                
      }
      this.submitFailure = true;   
    } finally {
      this.submitting = false;
    }
  };
  extractStudentInfo(student) {
    return {
      username: student.username || '',
      first_name: student.first_name || '',
      last_name: student.last_name || '',
      default_email: student.default_email || '',
    };
  }
  @action syncClassDetails = async (existingClass) => {
    this.submitSuccess = false;
    
    this.classToCreate.students = existingClass.students?.length > 0 ? await existingClass.students.map(
      (studentUserName) => {
        return existingClass?.student_info[studentUserName]
          ? this.extractStudentInfo(existingClass?.student_info[studentUserName])
          : null;
      },
    ): [];
    
    this.classToCreate.start_date = moment(existingClass.start_date).utc();
    this.classToCreate.end_date = moment(existingClass.end_date).utc();
    this.classToCreate.class_name = existingClass.class_name;
    this.classToCreate.academy_id = existingClass.academy_id;
    this.classToCreate.academy_region = existingClass.academy_region;
    this.classToCreate.initials = existingClass.initials;
    this.classToCreate.instructors = existingClass.instructors;
    this.classToCreate.decor = existingClass.decor;
    this.classToCreate.coa_percentage = existingClass.coa_percentage;
    this.classToCreate.label = existingClass.label;
    this.classToCreate.status = existingClass.status;
    this.classToCreate.course_lock_solution = existingClass.course_lock_solution || false;

    this.lockConfirmed = this.classToCreate.course_lock_solution;
    this.dateRange.to = moment(existingClass.end_date).toDate();
    this.dateRange.from = moment(existingClass.start_date).toDate();

    // to prefill form
    this.selectedCourse.courseName = existingClass.course_title;
    this.selectedCourse.courseSlug = existingClass.course_slug;
    this.students = this.classToCreate.students;
    this.selectedCourse.courseCode = existingClass.course_title.substr(
      0,
      existingClass.course_title.indexOf(' '),
    );

    // to prefill BYO on form
    this.byoConfirmed = existingClass.custom_class;
    if (this.byoConfirmed) {
      this.classToCreate.class_name = existingClass.class_name;
    }

    // to pass on classID
    this.classIdOfExistingClass = existingClass.doc_id;
  };

  @action editClass = async (t) => {
    this.submitting = true;
    this.classToCreate.students = this.students?.map((s) => {
      return {username: s?.username, default_email: s?.default_email};
    });
    this.classToCreate.course_title = this.selectedCourse.courseName;
    this.classToCreate.course_slug = this.selectedCourse.courseSlug;
    this.classToCreate.course_code = this.selectedCourse.courseCode;

    this.classToCreate.start_date = moment(this.dateRange.from).utc();
    this.classToCreate.end_date = moment(this.dateRange.to).utc();

    if (!this.byoConfirmed) {
      this.classToCreate.class_name = this.academyIdentifierForExistingClass;
    }

    this.classToCreate.academy_id = this.academyDetails.academy_id;
    this.classToCreate.academy_region = this.academyDetails.academy_region;
    this.classToCreate.initials = this.academyDetails.initials;
    
    this.classToCreate.custom_class = this.byoConfirmed;

    this.classToCreate.course_lock_solution = this.lockConfirmed;

    const file = this.fileForBYO.file;
    const classId = this.classIdOfExistingClass;
    const editedClassObject = this.classToCreate;
    try {
      const result = await patchClasses(editedClassObject, classId);

      if (result !== `Couldn't edit class`) {
        if (this.byoConfirmed && file) {
          const fileUploaded = await postFile(classId, file);

          if (fileUploaded !== `Couldn't upload file`) {
            this.submitSuccess = true;
            this.cancelForm();
          } else {
            this.submitFailure = true;
          }
        } else {
          this.submitSuccess = true;
          this.cancelForm();
        }
      }
    } catch (err) {
      if (
        err?.response?.status === 400 &&
        err.response.data.error.includes('can not assign student role')
      ) {
        this.classSaveMessage =
          t('Student already exists as Instructor. Please remove student:') +
          err.response.data.error.substring(
            t('can not assign student role').length,
          );
      }
      if (
        err?.response?.status === 400 &&
        err.response.data.error.includes('Invalid email')
      ) {
        this.classSaveMessage =
          t('Invalid email format used for student.');
      }
      if (
        err?.response?.status === 400 &&
        err.response.data.error.includes('Some students were already enrolled')
      ) {
        this.enrolledStudents = err.response.data.data;
        this.classSaveMessage =
          (this.enrolledStudents?.length) + 
          ' ' + 
          (this.enrolledStudents.length === 1 ? t('student from this list is ') : t('students from this list are ')) + 
          t('already enrolled in a previous ') + this.classToCreate.course_slug.toUpperCase() + 
          t(' course. Please click on "Proceed without duplicates" to continue creating a new class.');
      }
      this.submitFailure = true;
    } finally {
      this.submitting = false;
    }
  };

  @action exportEnrolledStudentCSV = () => {
    const header = "Red Hat Netwrok ID,Email address";
    const csvData = header + "\n" + this.enrolledStudents.map((row) => Object.values(row).join(',')).join('\n');
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const csvLink = document.createElement('a');

    csvLink.href = csvUrl;
    csvLink.download = 'Duplicate_enrollments.csv';
    csvLink.click();
  };

  @action removeEnrolledStudents = () => {
    this.enrolledStudents.forEach((student) => this.removeStudent(student));
    this.submitFailure = !this.submitFailure;
  };

  @action confirm = (e, confirmType) => {
    switch (confirmType) {
      case 'BYO':
        this.byoConfirmed = e.target.checked;
        this.ownConfirmed = false;
        this.classToCreate.course_title = '';
        this.iltConfirmed = false;
        this.classToCreate.custom_class = this.byoConfirmed;
        break;
      case 'Own':
        this.ownConfirmed = e.target.checked;
        break;
      case 'LOCK':
        this.lockConfirmed = e.target.checked;
        break;

      default:
        this.iltConfirmed = e.target.checked;
    }
  };

  @action cancelForm = () => {
    this.dateRange.from = undefined;
    this.dateRange.to = undefined;
    this.students = [];
    this.selectedCourse = {};
    this.isCalendarOpen = false;
    this.classToCreate = {
      students: [],
      course_title: '',
      start_date: '',
      end_date: '',
      class_name: '',
      course_slug: '',
      status: '',
      decor: '',
      label: '',
      coa_percentage: 0,
      custom_class: false,
    };
    this.iltConfirmed = false;
    this.byoConfirmed = false;
    this.submitting = false;
    this.studentsFromCSV = [];
    this.fileForBYO = {};
  };

  @action resetDropDown = () => {
    const _onlyUserNamesCSVs = this.studentsFromCSV.map((s) => s.username);
    const onlyManuallyStudents = this.students.filter(
      (s) => !_onlyUserNamesCSVs.includes(s.username),
    );
    this.students = onlyManuallyStudents;
    this.studentsFromCSV = [];
  };

  @action resetDropFile = () => {
    this.fileForBYO = {};
  };

  @action dismissAlert = () => {
    this.submitFailure = !this.submitFailure;
  };

  @action charCounter = (e) => {
    this.classToCreate.label = e.currentTarget.value;
  };

  @action addBYOName = (e) => {
    this.selectedCourse.courseName = e.currentTarget.value;
  };

  @action isStudentUniqueBy = (student, byFieldName) => {
    const _studentExists = this.students?.find(
      (s) =>
        s[byFieldName]?.toLowerCase() === student[byFieldName]?.toLowerCase(),
    );

    return _studentExists ? false : true;
  };

  @action isStudentSameAsInstructor = (student) => {
    return (
      this.userStore.username.toLowerCase().trim() ===
      student.username.toLowerCase().trim()
    );
  };

  @action onAddNewStudent = (student) => {
    student.username = student.username?.toLowerCase().trim();
    this.students.push(student);
  };
  @action toggleDeleteStudentModal = (student) => {
    this.showDeleteStudentModal = !this.showDeleteStudentModal;
    this.studentToDelete = student;
  };
  // Paginated Student List
  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @action toggleBulkUploadInfoPanel = () => {
    this.isInfoPanelOpen = !this.isInfoPanelOpen;
  };

  @computed get totalPages() {
    return (
      Math.ceil(this.students?.length / this.studentEntriesPerPage, 10) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get studentPaginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.studentEntriesPerPage;
    return this.students?.slice(
      startIndex,
      startIndex + this.studentEntriesPerPage,
    );
  }
}
export const CreateClassContext = createContext(null);
export default CreateClassStore;
