import { get, patch } from 'axios';
import {
  CURRENT_USER_RECORD_API,
  USER_RECORD_API,
  USER_SUBSCRIPTION_API,
  QUOTAS_API,
  CREDLY_USER_BADGE_API_URL,
} from '../config/constants';
export const getCurrentUserRecord = async () => {
  try {
    const user = await get(CURRENT_USER_RECORD_API);
    return user.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const setPreferredRole = async (roleName) => {
  try {
    const response = await patch(CURRENT_USER_RECORD_API, {
      preferred_role: roleName,
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserGroups = async (uuid) => {
  if (!uuid) {
    throw new Error('User UUID is required');
  }

  try {
    const groups = await get(`${USER_RECORD_API}${uuid}/groups`);
    return groups.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const patchUserRecord = (uuid, data) => {
  return patch(`${USER_RECORD_API}${uuid}`, data)
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getActiveSubscription = (uuid) => {
  return get(USER_SUBSCRIPTION_API.replace('<uuid>', uuid))
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getQuotas = (params) => {
  const quotaURL = params.class_id
    ? `${QUOTAS_API}/?class_id=${params.class_id}`
    : QUOTAS_API;
  return get(quotaURL)
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getUserCredlyBadges = async () => {
  try {
    const response = await get(`${CREDLY_USER_BADGE_API_URL}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
