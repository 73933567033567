import React, { useContext, useEffect, useState } from 'react';
import { useLocalStore } from 'mobx-react';
import { storesContext } from '../../stores';
import LabStore from '../../stores/LabStore';
import LabControlsStore, {
  labControlsContext,
} from '../../stores/LabControlsStore';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import ViewLoader from '../../components/Loading/View';
import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import LanguageDropdown from '../../components/Language/Dropdown';
import TableOfContents from '../../components/Course/TableOfContents';
import ProgressMap from '../../components/Course/ProgressMap';
import ContentArea from '../../components/Course/ContentArea';
import Navigation from '../../components/Course/Navigation';
import LabMaterials from '../../components/LabMaterials';
import { useTranslation } from 'react-i18next';
import { reaction, when } from 'mobx';
import LabEnvironment from '../../components/LabEnvironment';
import GenericAlert from '../../components/Banners/GenericAlert';
import { 
  ALLOWED_ADDITIONAL_LABS_ROL_COURSE,
  INSTRUCTOR_COURSE_COMPLETION_ATTENDANCE_PERCENTAGE,
} from '../../config/constants';
import VideoWidget from '../../components/Video/Widget';
import CertificateOfAttendanceButton from '../../components/CertificateOfAttendance/Button';
const LabStartDialog = React.lazy(() =>
  import('../../components/LabControls/LabStartDialog'),
);
const CourseView = () => {
  const { t } = useTranslation();
  const { routerStore, courseStore, classStore, userStore, progressStore, academyStore } =
    useContext(storesContext);

  const {
    activeTab,
    content,
    courseCode,
    courseInfo,
    structure,
    page,
    pageSlug,
    courseSlug,
    contentLoading,
    errorLoadingContent,
    translations,
    labMaintenanceBanners,
  } = courseStore;

  const labStore = useLocalStore(() => new LabStore());
  const { labDefinition } = labStore;
  const labControlsStore = useLocalStore(() => new LabControlsStore());
  const showLabStartDialog =
    labControlsStore.courseSlug &&
    !(labControlsStore.blueprints?.length > 1) &&
    !(labControlsStore.created || labControlsStore.started) &&
    courseStore.showAutostart &&
    userStore.hasLabHours;

  const [badges, setBadges] = useState([]);

  useEffect(() => {
    const init = async () => {
      courseStore.pageSlug = await courseStore.init();
    };

    init();
  }, [courseStore]);

  useEffect(() => {
    if (routerStore.route.params.course !== '' && labStore.courseSlug === '') {
      labStore.courseSlug = routerStore.route.params.course;
      labStore.getLabDefinition();
    }
  }, [labStore, routerStore]);

  useEffect(() => {
    // when courseinfo is fetched.
    if (!courseSlug) return;
    labStore.courseInfo = courseInfo;
    
    // Fetching page videos for eligible users
    if (courseStore.enableSelfPacedVideo){
      const getPageVideosForCourse = async (courseSlug) => {
        await courseStore.getPageVideosForCourse(courseSlug);
      };
      getPageVideosForCourse(courseSlug);
    }
  
  }, [courseInfo, courseSlug, labStore.courseInfo, courseStore, courseStore.enableSelfPacedVideo]);

  useEffect(() => {
    // Changing title when page changes
    if (courseCode && pageSlug) {
      const tabTitle = `${courseCode.toUpperCase()} - ${pageSlug}`;
      document.title = tabTitle;
    }
  }, [courseCode, pageSlug]);

  useEffect(() => {
    // Updating route when page in store changes
    const routePage = routerStore.route.params.page;
    const classId = routerStore.route.params.classid;
    if (!pageSlug || !courseSlug) {
      return;
    }
    if (userStore.isStudent && !classId) {
      return;
    }

    if (pageSlug !== routePage) {
      routerStore.router.replaceHistoryState(courseStore.coursePageRouteName, {
        course: courseSlug,
        page: pageSlug,
        ...courseStore.coursePageRouteParamsByRole,
      });

      courseStore.getCoursePage();
    }

    // Update preset filters when route changes
    reaction(
      () => routerStore?.route?.name,
      () => {
        if (
          (routerStore?.route?.name === 'summary' || routerStore?.route?.name === 'enablement' ||
          routerStore?.route?.name === 'classes' ) && courseStore.pageSlug !== ''
        ) {
          courseStore.pageSlug = '';
          courseStore.courseSlug = '';
          courseStore.structure = [];
          courseStore.contentLoading = false;
          courseStore.courseInfo = {};
          courseStore.page = {};
          courseStore._activeTab = 1;
          courseStore.classId = '';
        }
      },
    );
  }, [pageSlug, courseSlug, routerStore, courseStore, userStore]);

  useEffect(() => {
    // Keeping store updated when route changes
    const paramsPage = routerStore.route.params.page;
    if (routerStore.route.params.classid) {
      courseStore.classId = routerStore.route.params.classid;
    }
    if (
      courseSlug &&
      paramsPage &&
      paramsPage !== courseStore.pageSlug &&
      !contentLoading
    ) {
      courseStore.pageSlug = routerStore.route.params.page;
      courseStore.getCoursePage();
      courseStore.activeTab = '2';
    }
  }, [
    routerStore.route.params.page,
    routerStore.route.params.classid,
    courseStore,
    courseSlug,
    contentLoading,
  ]);

  const notAllowed = () => {
    courseStore.available = true;
    console.info('Course not available');
    return routerStore.navigate(userStore.role.defaultRoute);
  };

  const onTabChange = (key) => {
    courseStore.activeTab = key;
  };

  const courseProgress = () => {
    if (courseStore.courseCode !== '') {
      const found = progressStore.progressByCourse.find((course) => {
        const { course_code: code1 } = course || {};
        return (
          code1 === courseStore.courseCode
        );
      });

      const { total_progress: totalProgress } = found || {};

      const progress = found ? Math.round(totalProgress * 100) : 0;

      return progress;
    }
    return 0;
  };

  const isProgressCriteriaQualified = () => {
    if (userStore.isStudent){
      const classDetails = classStore.getClassDetailbyId(routerStore.route.params?.classid);
      const attendancePercentage = Math.round(classDetails?.coa_percentage * 100);
      return courseProgress() >= attendancePercentage;
    } else{      
      return courseProgress() >= INSTRUCTOR_COURSE_COMPLETION_ATTENDANCE_PERCENTAGE;
    }
  };

  const showAttendanceButton = isProgressCriteriaQualified();

  const dateToTimestamp = (date) => {
    const dateObj = date ? moment(date) : moment();
    return dateObj.toISOString();
  };

  const renderCourse = () => {
    const { next_tag: nextTag, page_tag: pageTag, prev_tag: previous } = page;
    let next = nextTag;

    if (nextTag) {
      // check that next page/chapter is available to the user
      const nextItem = structure.find((item) => item.page_tag === nextTag);
      next =
        nextTag &&
        nextItem &&
        nextItem.status !== 'inactive' &&
        nextItem.status !== 'hidden'
          ? nextTag
          : null;
    }

    const progressStructure = progressStore.progressDict[courseStore.entityValueToTrackProgressWith]?.structure ?
    progressStore.progressDict[courseStore.entityValueToTrackProgressWith].structure
      : progressStore.progressDict[courseSlug]?.structure || [];

    return (
      <React.Fragment>
        <ProgressMap
          structure={structure}
          progressStructure={progressStructure}
          pageSlug={pageSlug}
        />

        {!courseStore.courseInfoFetched && renderLoader()}

        {!courseStore.contentLoading ? (
          errorLoadingContent ? (
            'This content could not be displayed'
          ) : (
            <React.Fragment>                
              <Navigation next={next} previous={previous} />
              {courseStore.enableSelfPacedVideo && courseStore.pageWithVideo && (
                <VideoWidget
                  entryId={courseStore.pageWithVideo.entry_id}
                  playerId={courseStore.customVideoPlayerId}
                />
              )}
              <ContentArea
                key={pageTag}
                page={page}
                content={content}
              />
              <Navigation next={next} previous={previous} />
            </React.Fragment>
          )
        ) : (
          renderLoader()
        )}
      </React.Fragment>
    );
  };

  const renderTableContents = () => {
    return <TableOfContents structure={structure} />;
  };

  const renderLoader = () => {
    return (
      <div className="m-3">
        <ViewLoader />
      </div>
    );
  };

  const renderLabMaintenanceBanner = (notification) => {
    return (
      <GenericAlert
        type="warning"
        text={t(
          `
            We will have scheduled downtime on the labs in this course
            during ${notification.timeframe} for approximately ${notification.hours} hours.
            You may experience issues with labs during this time.
          `,
        )}
      />
    );
  };

  useEffect(() => {
    when(
      () => showAttendanceButton,
      async () => {   
        // Fetch user credly batches when progress reach threshold point     
        const credlyBadges = await userStore.getUserCredlyBadges(courseSlug);
        setBadges(credlyBadges);
      }
    );
  }, [userStore, courseSlug, showAttendanceButton]);

  return (
    <React.Fragment>
      <h3 className="view-page-title">
        {courseInfo?.title ? courseInfo.title : `${t('Loading')}...`}
      </h3>
      
      {courseStore.available ? (
        <div className="course-view m-3">
          <div className="utility-btn-wrapper text-right mb-2">
            {showAttendanceButton ? (                    
              <div className="certificate-btn-wrapper mr-2 d-inline-block">
                <CertificateOfAttendanceButton
                  courseSlug={courseSlug}
                  timestamp={dateToTimestamp(new Date())}
                  academyId={academyStore.academyDetails?.doc_id}
                  classId={routerStore.route.params?.classid}
                  badges={badges}
                />                            
              </div>
            ) : null}
            {translations.length > 1 && (
              <div className="translations-dropdown d-inline-block">
                <LanguageDropdown
                  callback={(lang) => {
                    courseStore.saveUsedTranslation(lang).then(() => {
                      courseStore.getCourseStructure(courseSlug);
                      courseStore.getCoursePage();
                    });
                  }}
                  courseSlug={courseSlug}
                  pageSlug={courseStore.pageSlug}
                  language={courseStore.language}
                  translations={translations}
                />
              </div>
            )}
          </div>          
          {showLabStartDialog ? (
            <labControlsContext.Provider value={labControlsStore}>
              <LabStartDialog />
            </labControlsContext.Provider>
          ) : null}

          {labMaintenanceBanners.map((notification) =>
            renderLabMaintenanceBanner(notification),
          )}

          <Tabs
            id="course-tabs"
            className="courseTabs"
            activeKey={activeTab}
            mountOnEnter
            animation="false"
            onSelect={onTabChange}
          >
            <Tab eventKey={1} title={t('Table of Contents')}>
              {courseStore.structureLoaded
                ? renderTableContents()
                : renderLoader()}
            </Tab>
            <Tab eventKey={2} title={t('Course')}>
              {renderCourse()}
            </Tab>

            {labDefinition.doc_id && courseSlug && courseSlug !== '' ? (
              <Tab eventKey={8} title={t('Lab Environment')}>
                <LabEnvironment
                  courseSlug={courseSlug}
                  labDefinition={labDefinition}
                  {...(userStore.isStudent && {
                    classId: courseStore.classId,
                  })}
                />
              </Tab>
            ) : null}
            {userStore.isStudent &&
              classStore.course &&
              ALLOWED_ADDITIONAL_LABS_ROL_COURSE.includes(
                courseCode.toLowerCase(),
              ) && (
                <Tab eventKey={3} title={t('Purchase Additional Labs')}>
                  <LabMaterials course={classStore.course} />
                </Tab>
              )}
          </Tabs>
        </div>
      ) : (
        notAllowed()
      )}
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(CourseView),
  GenericViewError,
  handleError,
);
