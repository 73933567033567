import React, { useContext, useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { when } from 'mobx';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import ViewLoader from '../../components/Loading/View';
import { storesContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Row,Col } from 'react-bootstrap';
import ExpandableCourseSummary from '../../components/ExpandableCourseSummary';
import LearningPath from '../../components/LearningPath';
import StudentAssignmentCard from '../../components/Cards/StudentAssignmentCard';
import CourseSummaryStore from './store';
import './styles.scss';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';

const CourseSummary = () => {
  const { t } = useTranslation();

  const title = t('My Courses');

  const { classStore, routerStore, uiStore, courseStore, userStore } = useContext(storesContext);

  const store = useLocalStore(() => new CourseSummaryStore(classStore));

  const [badges, setBadges] = useState([]);
  
  useEffect(() => {
    when(
      () => classStore.ready && !classStore.loading && !store.ready,
      async () => {
        try {
          classStore.loading = true;
          await store.populateCourses(true);          
          const credlyBadges = await userStore.getUserCredlyBadges();
          setBadges(credlyBadges);
          classStore.loading = false;
        } catch (error) {
          classStore.loading = false;
        }
      },
    );
    
  }, [classStore, classStore.ready, classStore.courses, store, store.ready, userStore]);
  
  useEffect(() => {
    // This useEffect is triggered only when the global language is changed.
    // When that happens, the course info is reloaded using the global language.
    // The reload is triggered setting classStore as not ready, loading the
    // initialization useEffect again.
    if (
      !classStore.loading &&
      classStore.ready &&
      uiStore.currentLanguage !== classStore.language
    ) {
      classStore.language = uiStore.currentLanguage;
      store.ready = false;
    }
  }, [
    uiStore.currentLanguage,
    classStore.ready,
    classStore.loading,
    classStore.language,
    store,
  ]);

  return (
    <div className="course-summary">
      <h3 className="view-page-title">{title}</h3>
        {!classStore.ready || classStore.loading ? (
          <div className="view-loader-wrapper">
            <ViewLoader />
          </div>
        ) : classStore.getClassesLength > 0 ? (
          <div>
          {store.paginatedCourses?.map((course, index) => 
            <section key={index} className="m-3">
            <Tabs defaultActiveKey="course" id="course-summary-tab">
              <Tab eventKey="course" title={t('Course')}>
                <ExpandableCourseSummary
                  course={course && course}
                  progress={classStore.getCourseProgress(course.class_id)}
                  routerStore={routerStore}
                  courseStore={courseStore}
                  badges={badges}
                />
              </Tab>
              {course && !course.customClass && (
                <Tab eventKey="learning-path" title={t('Learning Path')}>
                  <LearningPath
                    course={course && course}
                    store={classStore.learningPathStore}
                  />
                </Tab>
              )}
              
              <Tab eventKey="assignment" title={t('Assignment')}>
                <StudentAssignmentCard classId={course.class_id}  />
              </Tab>                            
            </Tabs>
            </section>
          )}

          <Row>
            <Col>
              {/* --- Pagination --- */}
              {store.paginatedCourses.length ? (
                <div className="course-list-pagination">
                  <UltimatePagination
                    currentPage={store.currentPage}
                    totalPages={store.totalPages}
                    onChange={store.setCurrentPage}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
          </div>
        ) : (
          <p>{t('You are not part of any course')}</p>
        )}
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseSummary),
  GenericViewError,
  handleError,
);
