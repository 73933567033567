import { computed, observable, action } from 'mobx';
import moment from 'moment';

class CertificateOfAttendanceButtonStore {
  @observable _certificateOfAttendanceModalVisibility = false;
  @observable entityValue = '';

  constructor(userStore, progressStore, courseStore, configStore) {
    this.userStore = userStore;
    this.progressStore = progressStore;
    this.courseStore = courseStore;
    this.configStore = configStore;
  }

  @computed get certificateOfAttendanceModalVisibility() {
    return this._certificateOfAttendanceModalVisibility;
  }

  set certificateOfAttendanceModalVisibility(visible) {
    this._certificateOfAttendanceModalVisibility = visible;
  }

  @action setEntityValue = (value) => { 
    this.entityValue = value ? value : '';
  };

  @action getCourseProgress(entityValue) {
    const entityKey = this.userStore.isStudent ? 'class_id' : 'course_slug';
    const progress = this.progressStore.getProgressForEntityId(
      entityValue,
      entityKey
    );
    return progress;
  }

  @action getBadgeURL(badges, courseSlug) {
    const filteredBadge = badges?.filter((item) => item.badge === courseSlug)[0];
    return filteredBadge ? filteredBadge.badge_url : null;
  }

  @computed get shouldShowLegacyCertificateOfAttendance() { 
    if(this.entityValue === ''){
      return undefined;
    }    

    const courseProgress = this.getCourseProgress(this.entityValue);

    const courseCompletionTime = courseProgress?.completion_time;
      
    const credlyBadgeReleaseDate = this.configStore.config.CREDLY_BADGE_RELEASE_DATE;

    if (credlyBadgeReleaseDate && courseCompletionTime) {

      const courseCompletionTimeUtc = moment.utc(courseCompletionTime);
      const credlyBadgeReleaseDateUtc = moment.utc(credlyBadgeReleaseDate);

      if (courseCompletionTimeUtc.isSameOrAfter(credlyBadgeReleaseDateUtc)) {
        return false;
      }

    } 

    return true;
  }
}

export default CertificateOfAttendanceButtonStore;
